import React from 'react';
import styled from 'styled-components';
import Button from 'antd/es/button';
import { textStyles } from '@allenai/varnish';

import { RobotImage } from './RobotImage';

const { Jumbo } = textStyles;

interface Props {
    onNextButtonClick: () => void;
}

export const Introduction = (props: Props) => {
    return (
        <React.Fragment>
            <h2 style={{ marginTop: '0' }}> Could you live with an AI and its morals? </h2>
            <ContentGrid>
                <div>
                    <Jumbo> Welcome! You are about to take a LabInTheWild test. </Jumbo>
                    <br />
                    <br />
                    <Jumbo>
                        {' '}
                        Tell us your moral judgments on certain situations and we will show you how
                        you compare to others’ and an AI.{' '}
                    </Jumbo>
                    <br />
                    <br />
                    <Jumbo>
                        {' '}
                        <b> The test usually takes 7 minutes to complete. </b>{' '}
                    </Jumbo>
                    <br /> <br /> <br />
                    <Button type="primary" onClick={props.onNextButtonClick} size="large">
                        Start the test!
                    </Button>
                </div>
                <RobotImage width="75%" />
            </ContentGrid>
        </React.Fragment>
    );
};

const ContentGrid = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr;
`;
