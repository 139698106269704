import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import AutoComplete from 'antd/es/auto-complete';
import Button from 'antd/es/button';
import Form from 'antd/es/form';
import Radio from 'antd/es/radio';
import { VictoryChart, VictoryAxis, VictoryBar, VictoryTheme } from 'victory';
import { ArrowRightOutlined, LoadingOutlined } from '@ant-design/icons';
import { textStyles, belowOrEqualTo, Theme } from '@allenai/varnish';

import { RobotImage, NextButton, StyledButton, SpeechBubble, CustomForm } from '.';
import { createAnnotation, getInstances, Instance, Annotation, InstanceReport } from '../api';

const { Jumbo, Big, Small } = textStyles;

interface Props {
    onNextButtonClick: () => void;
    questionNumber: number;
    numQuestions: number;
    countryLivedInLongest: string | undefined;
    sessionId?: number;
}

const AGREEMENT_STATEMENTS = [
    'Looks like we agree!',
    'Seems like we think the same.',
    'Yipee, we agree!',
];

const DISAGREEMENT_STATEMENTS = [
    'But I could be wrong.',
    'But you may be right.',
    "But, I'm not sure!",
    "Hm... Maybe I'm incorrect on this.",
];

const POSITIVE_EXAMPLES = [
    "It's nice",
    "It's kind",
    "It's good",
    "It's moral",
    "It's ethical",
    "It's polite",
    "It's respectful",
    "It's noble",
    "It's brave",
    "It's encouraged",
    "It's funny",
    "It's honorable",
    "It's healthy",
    'You should',
    'You can',
    'They should',
    'They can',
    'It should',
    'It can',
    'She should',
    'She can',
    'He should',
    'He can',
];

const NEGATIVE_EXAMPLES = [
    "It's bad",
    "It's wrong",
    "It's racist",
    "It's sexist",
    "It's offensive",
    "It's unusual",
    "It's immoral",
    "It's unethical",
    "It's rude",
    "It's discouraged",
    "It's reprehensible",
    "It's greedy",
    "It's disgusting",
    "It's unhealthy",
    "It's gross",
    "You shouldn't",
    'You cannot',
    "You can't",
    "They shouldn't",
    'They cannot',
    "They can't",
    "It shouldn't",
    'It cannot',
    "It can't",
    "She shouldn't",
    'She cannot',
    "She can't",
    "He shouldn't",
    'He cannot',
    "He can't",
];

const NEUTRAL_EXAMPLES = [
    "It's okay",
    'It depends',
    "It's expected",
    "It's normal",
    "It's fine",
    "It's common",
    "It's discretionary",
    "It's reasonable",
    "It's understandable",
    "It's allowed",
    "It's justified",
    "It's justifiable",
    "It's difficult",
];

export const DataCollection = (props: Props) => {
    const allOptions = NEUTRAL_EXAMPLES.concat(POSITIVE_EXAMPLES)
        .concat(NEGATIVE_EXAMPLES)
        .map((value: string) => {
            return { value: value };
        });

    const [annotation, setAnnotation] = useState<Annotation>({
        class: undefined,
        text: undefined,
    });
    const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
    const [options, setOptions] = useState<{ value: string }[]>(allOptions);
    const [instance, setInstance] = useState<Instance>();
    const [report, setReport] = useState<InstanceReport>();
    const [instances, setInstances] = useState<Instance[]>([]);
    const [isInstancesLoading, setIsInstancesLoading] = useState<boolean>(true);
    const [isAnnotationLoading, setIsAnnotationLoading] = useState<boolean>(false);

    const [form] = Form.useForm();
    const colorMap = {
        '-2': Theme.color.R8.hex,
        '-1': Theme.color.R3.hex,
        '0': Theme.color.N5.hex,
        '1': Theme.color.G3.hex,
        '2': Theme.color.G8.hex,
    };

    useEffect(() => {
        if (props.countryLivedInLongest !== undefined && props.numQuestions !== undefined) {
            getInstances(props.countryLivedInLongest, props.numQuestions)
                .then((data) => {
                    // Shuffling algorithm from https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
                    const shuffledData = data
                        .map((instance: Instance) => {
                            return {
                                instance: instance,
                                value: Math.random(),
                            };
                        })
                        .sort(
                            (
                                a: { instance: Instance; value: number },
                                b: { instance: Instance; value: number }
                            ) => {
                                return a.value - b.value;
                            }
                        )
                        .map((element: { instance: Instance; value: number }) => {
                            return element.instance;
                        });
                    setInstances(shuffledData);
                    setInstance(shuffledData[props.questionNumber - 1]);
                    setIsInstancesLoading(false);
                })
                .catch(() => {
                    alert('Could not get situations! Please try again.');
                    setIsInstancesLoading(false);
                });
        }
    }, ['']);

    const onNextButtonClick = () => {
        setAnnotation({
            class: undefined,
            text: undefined,
        });
        setHasSubmitted(false);
        form.resetFields();

        // Get another instance for the user to annotate
        if (props.questionNumber < props.numQuestions) {
            setInstance(instances[props.questionNumber]);
        }

        props.onNextButtonClick();
    };

    const submitAnnotation = async (skipped: boolean) => {
        if (
            props.sessionId !== undefined &&
            props.countryLivedInLongest &&
            instance !== undefined
        ) {
            // const skipped = annotation.class === undefined;
            const annotationToSubmit = skipped
                ? {
                      class: 100,
                      text: 'skipped annotation',
                  }
                : annotation;

            setIsAnnotationLoading(true);
            try {
                const instanceReport = await createAnnotation(
                    props.sessionId,
                    instance.instanceId,
                    props.countryLivedInLongest,
                    annotationToSubmit
                );
                setHasSubmitted(true);
                if (!skipped) {
                    setReport(instanceReport);
                } else {
                    setReport(undefined);
                    setHasSubmitted(false);
                }
                setIsAnnotationLoading(false);
            } catch {
                setIsAnnotationLoading(false);
                alert('Could not submit your answer! Please try again.');
            }
        }
    };

    const onSearch = (query: string) => {
        const newOptions = allOptions.filter((value) =>
            value.value.toLowerCase().includes(query.toLowerCase())
        );
        setOptions(newOptions);
    };

    const getFormattedString = (string: string): string => {
        if (string !== undefined) {
            return string.charAt(0).toUpperCase() + string.toLowerCase().substring(1);
        }
        return '';
    };

    const formattedAction = instance !== undefined ? getFormattedString(instance.action) : '';

    const getPolarity = (num: number | undefined) => {
        if (num === undefined) {
            return undefined;
        } else if (num < 0) {
            return -1;
        } else if (num === 0) {
            return 0;
        } else {
            return 1;
        }
    };

    const getFollowupText = (instance: Instance, annotation: Annotation) => {
        const match = getPolarity(instance.class) === getPolarity(annotation.class);
        const choices = match ? AGREEMENT_STATEMENTS : DISAGREEMENT_STATEMENTS;

        // Code from https://stackoverflow.com/questions/4550505/getting-a-random-value-from-a-javascript-array
        return choices[Math.floor(Math.random() * choices.length)];
    };

    return (
        <React.Fragment>
            {!isInstancesLoading ? (
                <React.Fragment>
                    <h5 style={{ textAlign: 'center', marginTop: '0' }}>
                        {props.questionNumber} / {props.numQuestions}
                    </h5>
                    <BoxedH3>{formattedAction}</BoxedH3>
                    {/* <p style={{ textAlign: 'center' }}>
                        <Big style={{ fontSize: '1.25em' }}>Example: {formattedSituation}</Big>
                    </p> */}
                    <p style={{ textAlign: 'center' }}>
                        <Big>
                            <i>
                                <span style={{ color: 'red', fontWeight: 900 }}>*</span> denotes a
                                required field.
                            </i>
                        </Big>
                    </p>
                    <CustomForm
                        form={form}
                        validateMessages={{ required: '' }}
                        name="demographics"
                        labelCol={{ span: 10 }}
                        wrapperCol={{ span: 14 }}>
                        <Form.Item
                            name="class"
                            label="What do you think about the action from a moral perspective?"
                            rules={[{ required: true }]}>
                            <Radio.Group
                                optionType="button"
                                buttonStyle="solid"
                                value={undefined}
                                onChange={(event: any) =>
                                    setAnnotation({
                                        class: parseInt(event.target.value),
                                        text: annotation.text,
                                    })
                                }
                                disabled={hasSubmitted || isAnnotationLoading}>
                                <Radio.Button value="-2">Very bad</Radio.Button>
                                <Radio.Button value="-1">Bad</Radio.Button>
                                <Radio.Button value="0">Expected / OK</Radio.Button>
                                <Radio.Button value="1">Good</Radio.Button>
                                <Radio.Button value="2">Very good</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item name="text" label="Feel free to explain your judgment.">
                            <p style={{ marginBottom: '4px' }}>
                                <Small>(e.g., it's ok, you should, it's unusual)</Small>
                            </p>
                            <AutoComplete
                                disabled={hasSubmitted || isAnnotationLoading}
                                options={[
                                    {
                                        label: <span>Positive</span>,
                                        options: options
                                            .filter((element) =>
                                                POSITIVE_EXAMPLES.includes(element.value)
                                            )
                                            .map((element) => {
                                                return {
                                                    value: element.value,
                                                    label: <div>{element.value}</div>,
                                                };
                                            }),
                                        text: 'Positive',
                                    },
                                    {
                                        label: <span>Neutral</span>,
                                        options: options
                                            .filter((element) =>
                                                NEUTRAL_EXAMPLES.includes(element.value)
                                            )
                                            .map((element) => {
                                                return {
                                                    value: element.value,
                                                    label: <div>{element.value}</div>,
                                                };
                                            }),
                                        text: 'Neutral',
                                    },
                                    {
                                        label: <span>Negative</span>,
                                        options: options
                                            .filter((element) =>
                                                NEGATIVE_EXAMPLES.includes(element.value)
                                            )
                                            .map((element) => {
                                                return {
                                                    value: element.value,
                                                    label: <div>{element.value}</div>,
                                                };
                                            }),
                                        text: 'Negative',
                                    },
                                ].sort((a, b) => {
                                    const getPolarityFromHTML = (label: string) => {
                                        return label === 'Positive'
                                            ? 1
                                            : label === 'Negative'
                                            ? -1
                                            : 0;
                                    };
                                    if (annotation.class !== undefined) {
                                        const aDifference = Math.abs(
                                            annotation.class - getPolarityFromHTML(a.text)
                                        );
                                        const bDifference = Math.abs(
                                            annotation.class - getPolarityFromHTML(b.text)
                                        );

                                        return aDifference - bDifference;
                                    }
                                    return 0;
                                })}
                                onSearch={onSearch}
                                placeholder="Enter a short description (begin typing to see examples)"
                                value={undefined}
                                onChange={(value: string) =>
                                    setAnnotation({ class: annotation.class, text: value })
                                }
                            />
                        </Form.Item>
                        <Form.Item wrapperCol={{ offset: 10, span: 14 }}>
                            <Button
                                htmlType="submit"
                                type="primary"
                                disabled={
                                    annotation.class === undefined ||
                                    hasSubmitted ||
                                    isAnnotationLoading
                                }
                                onClick={() => submitAnnotation(false)}>
                                {!isAnnotationLoading ? (
                                    'Submit'
                                ) : (
                                    <React.Fragment>
                                        Loading <LoadingOutlined />
                                    </React.Fragment>
                                )}
                            </Button>
                        </Form.Item>
                    </CustomForm>
                    {hasSubmitted && report && instance && (
                        <ContentGrid>
                            <div>
                                <SpacedBlockJumbo>The AI speculates:</SpacedBlockJumbo>
                                <RobotGrid>
                                    <RobotImage width="200px" />
                                    <SpeechBubble
                                        text={
                                            <React.Fragment>
                                                <Big>
                                                    I speculate that{' '}
                                                    <b style={{ textDecoration: 'underline' }}>
                                                        {instance?.text.toLowerCase()}
                                                    </b>
                                                    . {getFollowupText(instance, annotation)}
                                                </Big>
                                            </React.Fragment>
                                        }
                                    />
                                </RobotGrid>
                            </div>
                            <ChartContainer>
                                <SpacedBlockJumbo>
                                    Study participants in {props.countryLivedInLongest} said:
                                </SpacedBlockJumbo>
                                <VictoryChart
                                    theme={VictoryTheme.material}
                                    domainPadding={15}
                                    padding={{ top: 0, bottom: 50, left: 50, right: 50 }}
                                    height={150}
                                    width={300}>
                                    <VictoryAxis
                                        tickValues={['-2', '-1', '0', '1', '2']}
                                        tickFormat={[
                                            'Very\nbad',
                                            'Bad',
                                            'Expected/\nOK',
                                            'Good',
                                            'Very\ngood',
                                        ]}
                                        style={{ tickLabels: { fontSize: 8, padding: 5 } }}
                                    />
                                    <VictoryAxis
                                        dependentAxis
                                        tickFormat={(x) => `${x * 100}%`}
                                        style={{ tickLabels: { fontSize: 8, padding: 5 } }}
                                    />
                                    <VictoryBar
                                        data={Object.keys(report).map((key: string) => {
                                            return {
                                                rating: key,
                                                score: (report as any)[key] as string,
                                                fill: (colorMap as any)[key] as string,
                                            };
                                        })}
                                        x="rating"
                                        y="score"
                                        style={{
                                            data: {
                                                fill: ({ datum }) => datum.fill,
                                            },
                                        }}
                                    />
                                </VictoryChart>
                            </ChartContainer>
                        </ContentGrid>
                    )}
                    {hasSubmitted ? (
                        <NextButton
                            onClick={onNextButtonClick}
                            disabled={!hasSubmitted || isAnnotationLoading}
                            message={
                                isAnnotationLoading ? (
                                    <React.Fragment>
                                        {' '}
                                        Loading <LoadingOutlined />{' '}
                                    </React.Fragment>
                                ) : undefined
                            }
                        />
                    ) : (
                        <StyledButton
                            onClick={() => {
                                submitAnnotation(true);
                                onNextButtonClick();
                            }}>
                            Skip <ArrowRightOutlined />
                        </StyledButton>
                    )}
                </React.Fragment>
            ) : (
                <React.Fragment>
                    Loading <LoadingOutlined />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

const ContentGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${({ theme }) => `${theme.spacing.lg}`};
    margin-top: ${({ theme }) => `${theme.spacing.xl3}`};
    padding: ${({ theme }) => `${theme.spacing.lg}`};
    border: 1px solid ${({ theme }) => `${theme.color.N5}`};
    border-radius: 4px;

    @media ${({ theme }) => belowOrEqualTo(theme.breakpoints.lg)} {
        grid-template-columns: 1fr;
    }
`;

const RobotGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
`;

const SpacedBlockJumbo = styled(Jumbo)`
    display: block;
    margin-bottom: ${({ theme }) => `${theme.spacing.lg}`};
`;

const ChartContainer = styled.div`
    .VictoryContainer {
        max-height: 500px;
    }
`;

const BoxedH3 = styled.h3`
    text-align: center;
    margin-top: 0;
    margin-bottom: ${({ theme }) => `${theme.spacing.md}`};
    border-radius: 4px;
    background-color: ${({ theme }) => `${theme.color.B1}`};
    padding: ${({ theme }) => `${theme.spacing.md}`};
`;
