/**
 * This is the top-level component that defines your UI application.
 *
 * This is an appropriate spot for application wide components and configuration,
 * stuff like application chrome (headers, footers, navigation, etc), routing
 * (what urls go where), etc.
 *
 * @see https://github.com/reactjs/react-router-tutorial/tree/master/lessons
 */

import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { Content, Layout } from '@allenai/varnish';
import PageHeader from 'antd/es/page-header';

import { Home, ViewReport } from './pages';
import { AppRoute } from './AppRoute';
import { LabInTheWildImage } from './components';

/**
 * An array capturing the available routes in your application. You can
 * add or remove routes here.
 */
const ROUTES: AppRoute[] = [
    {
        path: '/',
        label: 'Home',
        component: Home,
    },
    {
        path: '/report/:id',
        label: 'Report',
        component: ViewReport,
    },
];

export const App = () => {
    return (
        <BrowserRouter>
            <Route path="/">
                <Layout bgcolor="white">
                    <PageHeader backIcon={false} title={<LabInTheWildImage width={'200px'} />} />
                    <Content main style={{ marginTop: '32px' }}>
                        {ROUTES.map(({ path, component }) => (
                            <Route key={path} path={path} exact component={component} />
                        ))}
                    </Content>
                </Layout>
            </Route>
        </BrowserRouter>
    );
};
