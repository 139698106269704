import React from 'react';
import styled from 'styled-components';
import { textStyles, belowOrEqualTo } from '@allenai/varnish';

import exampleSrc from '../images/example.png';
import { NextButton } from '.';

const { Big } = textStyles;

interface Props {
    onNextButtonClick: () => void;
    numQuestions: number;
}

export const Instructions = (props: Props) => {
    return (
        <React.Fragment>
            <h3>Instructions</h3>
            <p>
                <Big>
                    You will be shown {props.numQuestions} different scenarios. For each, select
                    what you think about the situation in general from an ethical perspective. You
                    can elaborate on your perspective if you wish. Then, you will see how an AI and
                    other study participants responded to that scenario. <br />
                    <br />
                    At the end of the study, we will show how your responses compared with an AI's
                    and other participants' overall.
                </Big>
            </p>
            <ResponsiveInstructionsImg src={exampleSrc} alt="Example of the experiment page" />
            <NextButton onClick={props.onNextButtonClick} message="Let's start!" />
        </React.Fragment>
    );
};

const ResponsiveInstructionsImg = styled.img`
    margin: auto;
    max-height: 550px;
    display: block;

    @media ${({ theme }) => belowOrEqualTo(theme.breakpoints.lg)} {
        width: 100%;
    }
`;
