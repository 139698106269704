import React, { useState } from 'react';

import { Session, UserDemographics } from '../api';

import {
    DataCollection,
    Demographics,
    Introduction,
    IRB,
    Feedback,
    Report,
    PlayAgain,
    Instructions,
} from '../components';

const NUM_QUESTIONS_PER_SESSION = 25;

export const Home = () => {
    const [page, setPage] = useState<number>(0);
    const [session, setSession] = useState<Session>();
    const [numQuestionsAnswered, setNumQuestionsAnswered] = useState<number>(0);
    const [demographics, setDemographics] = useState<UserDemographics>();

    const incrementNumQuestionsAnswered = () => {
        const incrementedNumQuestionsAnswer = numQuestionsAnswered + 1;
        setNumQuestionsAnswered(incrementedNumQuestionsAnswer);
        if (incrementedNumQuestionsAnswer === NUM_QUESTIONS_PER_SESSION) {
            setPage(5);
        }
    };

    const resetState = () => {
        setPage(0);
        setSession(undefined);
        setNumQuestionsAnswered(0);
        setDemographics(undefined);
    };

    return (
        <React.Fragment>
            {page === 0 && <Introduction onNextButtonClick={() => setPage(1)} />}
            {page === 1 && (
                <IRB
                    onNextButtonClick={() => setPage(2)}
                    numQuestions={NUM_QUESTIONS_PER_SESSION}
                />
            )}
            {page === 2 && (
                <Demographics
                    onNextButtonClick={() => setPage(3)}
                    setSession={setSession}
                    setDemographics={setDemographics}
                />
            )}
            {page === 3 && (
                <Instructions
                    onNextButtonClick={() => setPage(4)}
                    numQuestions={NUM_QUESTIONS_PER_SESSION}
                />
            )}
            {page === 4 && (
                <DataCollection
                    onNextButtonClick={incrementNumQuestionsAnswered}
                    questionNumber={numQuestionsAnswered + 1}
                    numQuestions={NUM_QUESTIONS_PER_SESSION}
                    countryLivedInLongest={demographics?.countryLivedInLongest}
                    sessionId={session?.sessionId}
                />
            )}
            {page === 5 && (
                <Feedback onNextButtonClick={() => setPage(6)} sessionId={session?.sessionId} />
            )}
            {page === 6 && (
                <Report
                    onNextButtonClick={() => setPage(7)}
                    demographics={demographics}
                    sessionId={session?.sessionId}
                    shortUuid={session?.shortUuid}
                />
            )}
            {page === 7 && <PlayAgain onNextButtonClick={resetState} />}
        </React.Fragment>
    );
};
