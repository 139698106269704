import React from 'react';

import { ProgressVisualization, RadarVisualization } from '.';
import { AiReport } from '../api';

interface Props {
    report: AiReport;
    color?: string;
    otherReport?: AiReport;
    otherColor?: string;
    formatMap: any;
}

export const Visualization = (props: Props) => {
    if (props.report === undefined) {
        return null;
    } else if (Object.keys(props.report).length <= 2) {
        return (
            <ProgressVisualization
                report={props.report}
                formatMap={props.formatMap}
                color={props.color}
            />
        );
    } else {
        return (
            <RadarVisualization
                report={props.report}
                color={props.color}
                otherReport={props.otherReport}
                otherColor={props.otherColor}
                formatMap={props.formatMap}
            />
        );
    }
};
