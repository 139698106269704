import React from 'react';
import styled from 'styled-components';
import { textStyles } from '@allenai/varnish';

import { StyledProgress } from '.';
import { AiReport } from '../api';

const { Big } = textStyles;

interface Props {
    report: AiReport;
    formatMap: any;
    color?: string;
}

export const ProgressVisualization = (props: Props) => {
    const keys = Object.keys(props.report);
    return (
        <ProgressContainer singleColumn={keys.length === 1}>
            {keys
                .map((key) => {
                    const agree = (props.report as any)[key].agree;
                    const disagree = (props.report as any)[key].disagree;
                    return {
                        text: props.formatMap[key].text,
                        label: agree / (agree + disagree),
                    };
                })
                .sort((a, b) => {
                    return b.label - a.label;
                })
                .map((data, index) => {
                    return (
                        <div key={index}>
                            <StyledProgress
                                type="circle"
                                format={(percent: any) => percent + '%' + '\n' + 'match'}
                                percent={Math.round(data.label * 100)}
                                strokeColor={props.color}
                            />
                            <ProgressLabel>{data.text}</ProgressLabel>
                        </div>
                    );
                })}
        </ProgressContainer>
    );
};

const ProgressContainer = styled.div<{ singleColumn: boolean }>`
    display: grid;
    grid-template-columns: ${({ singleColumn }) => (singleColumn ? '1fr' : 'repeat(2, 1fr)')};
    grid-gap: ${({ theme }) => `${theme.spacing.lg}`};
    margin-bottom: ${({ theme }) => `${theme.spacing.xl}`};
`;

const ProgressLabel = styled(Big)`
    display: block;
    margin: auto;
    text-align: center;
    margin-top: ${({ theme }) => `${theme.spacing.md}`};
    font-weight: 900;
    font-size: 1.5em;
`;
