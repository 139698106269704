import axios from 'axios';

import { Instance } from '.';

const SESSIONS_LOCAL_STORAGE_KEY = 'delphiLitwSessionIds';

export function getInstances(country: string, numQuestions: number): Promise<Instance[]> {
    const oldSessionIdsString = localStorage.getItem(SESSIONS_LOCAL_STORAGE_KEY);

    return axios
        .get<Instance[]>('/api/instance', {
            params: {
                country: country,
                numQuestions: numQuestions,
                sessionIdsString: oldSessionIdsString || '',
            },
        })
        .then((resp) => resp.data);
}
