import axios from 'axios';

import { SessionFeedback } from '.';

export function createFeedback(sessionId: number, feedback: SessionFeedback): Promise<{}> {
    const payload = {
        sessionId: sessionId,
        feedback: feedback,
    };
    return axios.post<{}>('/api/feedback', payload).then((resp) => resp.data);
}
