import React from 'react';
import styled from 'styled-components';

import robotSrc from '../images/robot.png';

interface Props {
    width: string | number;
}

export const RobotImage = (props: Props) => {
    return (
        <SizedImg width={props.width} src={robotSrc} alt="A cute, friendly-looking cartoon robot" />
    );
};

const SizedImg = styled.img<{ width: string | number }>`
    margin: auto;
    width: ${({ width }) => width};
`;
