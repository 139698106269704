import React from 'react';
import { useParams } from 'react-router-dom';

import { Report } from '../components';

export const ViewReport = () => {
    const { id } = useParams<{ id: string }>();
    return (
        <React.Fragment>
            <Report shortUuid={id} />
        </React.Fragment>
    );
};
