import React, { useState, useEffect } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { textStyles } from '@allenai/varnish';

import { NextButton, Why, DemographicsForm } from '.';
import { createSession, decrypt, encrypt, Session, UserDemographics } from '../api';

const { Jumbo, Big } = textStyles;

interface Props {
    onNextButtonClick: () => void;
    setSession: (session: Session | undefined) => void;
    setDemographics: (demographics: UserDemographics) => void;
}

const DEMOGRAPHICS_LOCAL_STORAGE_KEY = 'delphiLitwDemographics';
const SESSIONS_LOCAL_STORAGE_KEY = 'delphiLitwSessionIds';

export const Demographics = (props: Props) => {
    const [demographics, setDemographics] = useState<UserDemographics>({
        hasTakenTest: undefined,
        gender: undefined,
        ethnicities: undefined,
        religion: undefined,
        educationLevel: undefined,
        countryLivedInLongest: undefined,
        countryOfResidence: undefined,
        nativeLanguage: undefined,
    });
    const [ethnicitiesSelfDescribed, setEthnicitiesSelfDescribed] = useState<string>();
    const [shoudSaveDemographics, setShouldSaveDemographics] = useState<boolean>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const savedDemographicsString = localStorage.getItem(DEMOGRAPHICS_LOCAL_STORAGE_KEY);
        if (savedDemographicsString !== null) {
            setIsLoading(true);
            decrypt(savedDemographicsString)
                .then((decodedString) => {
                    const savedDemographics = JSON.parse(decodedString);
                    // Make sure to mark has taken test as true!
                    savedDemographics.hasTakenTest = true;
                    submitDemographics(savedDemographics);
                })
                .catch(() => {
                    alert('Could not decrypt demographics. Please try again.');
                    setIsLoading(false);
                });
        }
    }, ['']);

    const showDemographicsCollectionRationale = () => {
        const alertMessage =
            'This information is for showing you your results and data analysis. None of your answers are personally identifiable. We take your privacy very seriously.';
        alert(alertMessage);
    };

    const submitDemographics = (demographics: UserDemographics) => {
        if (ethnicitiesSelfDescribed !== undefined && ethnicitiesSelfDescribed.trim() !== '') {
            demographics.ethnicities = demographics.ethnicities
                ? demographics.ethnicities
                      .concat(ethnicitiesSelfDescribed)
                      .filter((value: string) => value !== 'self-describe')
                : [ethnicitiesSelfDescribed];
        }

        setIsLoading(true);

        saveDemographics();

        createSession(demographics)
            .then(async (data) => {
                props.setSession(data);
                props.setDemographics(demographics);
                await saveSession(data.sessionId);
                setIsLoading(false);
                props.onNextButtonClick();
            })
            .catch(() => {
                alert('Could not create a session! Please try again.');
                setIsLoading(false);
            });
    };

    const saveDemographics = () => {
        if (shoudSaveDemographics) {
            encrypt(JSON.stringify(demographics))
                .then((encryptedString) => {
                    localStorage.setItem(DEMOGRAPHICS_LOCAL_STORAGE_KEY, encryptedString);
                })
                .catch(() => {
                    alert('Could not encrypt demographics. Please try again.');
                });
        }
    };

    const saveSession = async (sessionId: number) => {
        const oldSessionIdsString = localStorage.getItem(SESSIONS_LOCAL_STORAGE_KEY);
        try {
            let oldSessionIds;
            if (oldSessionIdsString) {
                const decryptedString = await decrypt(oldSessionIdsString);
                oldSessionIds = JSON.parse(decryptedString);
            } else {
                oldSessionIds = [];
            }
            oldSessionIds.push(sessionId);

            const encryptedData = await encrypt(JSON.stringify(oldSessionIds));
            localStorage.setItem(SESSIONS_LOCAL_STORAGE_KEY, encryptedData);
        } catch {
            alert('Could not encrypt or decrypt session data. Please try again.');
        }
    };

    const updateDemographics = (key: string, value: any) => {
        if (key === 'isPersonalComputer') {
            setShouldSaveDemographics(value);
        } else {
            if (key === 'hasTakenTest') {
                demographics.hasTakenTest = value === 'yes';
            } else if (key === 'gender') {
                demographics.gender = value as string;
            } else if (key === 'ethnicities') {
                demographics.ethnicities = value as string[];
            } else if (key === 'ethnicitiesSelfDescribed') {
                setEthnicitiesSelfDescribed(value);
            } else if (key === 'religion') {
                demographics.religion = value as string;
            } else if (key === 'educationLevel') {
                demographics.educationLevel = value as string;
            } else if (key === 'countryLivedInLongest') {
                demographics.countryLivedInLongest = value as string;
            } else if (key === 'countryOfResidence') {
                demographics.countryOfResidence = value as string;
            } else if (key === 'nativeLanguage') {
                demographics.nativeLanguage = value as string;
            } else {
                demographics.age = Math.max(1, value as number);
            }

            // Create copy so that reference is changed so page updates correctly
            const demographicsCopy = {
                hasTakenTest: demographics.hasTakenTest,
                gender: demographics.gender,
                ethnicities: demographics.ethnicities,
                religion: demographics.religion,
                educationLevel: demographics.educationLevel,
                countryLivedInLongest: demographics.countryLivedInLongest,
                countryOfResidence: demographics.countryOfResidence,
                nativeLanguage: demographics.nativeLanguage,
                age: demographics.age,
            };
            setDemographics(demographicsCopy);
        }
    };

    return (
        <React.Fragment>
            <p>
                <Jumbo>
                    Please tell us a bit about yourself.{' '}
                    <Why onClick={showDemographicsCollectionRationale}>Why?</Why>
                </Jumbo>
            </p>
            <p>
                <Big>
                    <i>
                        <span style={{ color: 'red', fontWeight: 900 }}>*</span> denotes a required
                        field. The more information you enter, the more detailed results we can show
                        you!
                    </i>
                </Big>
            </p>
            <DemographicsForm
                demographics={demographics}
                isLoading={isLoading}
                updateDemographics={updateDemographics}
            />
            <NextButton
                onClick={() => submitDemographics(demographics)}
                disabled={
                    demographics.countryLivedInLongest === undefined ||
                    demographics.hasTakenTest === undefined ||
                    isLoading
                }
                message={
                    isLoading ? (
                        <React.Fragment>
                            {' '}
                            Loading <LoadingOutlined />{' '}
                        </React.Fragment>
                    ) : undefined
                }
            />
        </React.Fragment>
    );
};
