import React from 'react';
import styled from 'styled-components';
import Button from 'antd/es/button';
import Divider from 'antd/es/divider';
import { FacebookFilled, LinkedinFilled, TwitterSquareFilled } from '@ant-design/icons';
import { textStyles, belowOrEqualTo } from '@allenai/varnish';

import { RobotImage, SpeechBubble } from '.';

const { Jumbo } = textStyles;

interface Props {
    onNextButtonClick: () => void;
}

export const PlayAgain = (props: Props) => {
    return (
        <React.Fragment>
            <h3 style={{ textAlign: 'center' }}>Thank you for participating in the study.</h3>
            <p style={{ textAlign: 'center' }}>
                <Jumbo>
                    Your results will help us understand how your moral beliefs differ from what a
                    machine was taught to create more ethically-aware AI.
                </Jumbo>
            </p>
            <ShareResults style={{ textAlign: 'center' }}>
                <Jumbo style={{ display: 'block' }}>
                    <b>Share the study with your friends:</b>
                </Jumbo>
                <Jumbo>
                    <a
                        href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdelphi-litw.apps.allenai.org"
                        target="_blank"
                        rel="noreferrer">
                        <FacebookFilled />
                    </a>
                    <a
                        href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fdelphi-litw.apps.allenai.org%2F&text=Tell%20us%20what%20you%20believe%20about%20certain%20situations%20and%20we%20will%20show%20you%20how%20you%20compare%20to%20others%E2%80%99%20and%20an%20AI.%0A"
                        target="_blank"
                        rel="noreferrer">
                        <TwitterSquareFilled />
                    </a>
                    <a
                        href="http://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fdelphi-litw.apps.allenai.org%2F&title=Could%20you%20live%20with%20an%20AI%20and%20its%20morals%3F%0A&summary=Tell%20us%20what%20you%20believe%20about%20certain%20situations%20and%20we%20will%20show%20you%20how%20you%20compare%20to%20others%E2%80%99%20and%20an%20AI.%0A"
                        target="_blank noreferrer">
                        <LinkedinFilled />
                    </a>
                </Jumbo>
            </ShareResults>
            <Divider />
            <PlayAgainContainer>
                <div>
                    <h4>Get more results!</h4>
                    <p>
                        <Jumbo>
                            We have other situations for you to compare yourself against the AI and
                            others, so you can see new results about yourself.
                        </Jumbo>
                    </p>
                    <Button
                        // style={{ margin: 'auto', display: 'block' }}
                        onClick={props.onNextButtonClick}
                        type="primary"
                        size="large">
                        Play again!
                    </Button>
                </div>
                <RobotGrid>
                    <RobotImage width="180px" />
                    <SpeechBubble
                        text={
                            <React.Fragment>
                                <Jumbo>Play another round to see more results!</Jumbo>
                            </React.Fragment>
                        }
                    />
                </RobotGrid>
            </PlayAgainContainer>
            <Divider />
            <h4 style={{ textAlign: 'center' }}>If you enjoyed the study, try these...</h4>
            <ExperimentContainer>
                <img src="http://www.labinthewild.org/images/reading-assessment.jpg" />
                <p>
                    <a href="https://reading.labinthewild.org/" target="_blank" rel="noreferrer">
                        How well can AI understand your speech?
                    </a>{' '}
                    <br />
                    Find out how well AI can understand your speech by reading out loud a few words
                    and sentences! The test takes around 7 minutes.
                </p>
                <img src="http://www.labinthewild.org/images/robot-puzzles-logo.png" />
                <p>
                    <a href="https://litw.cs.ualberta.ca/" target="_blank" rel="noreferrer">
                        Do you solve puzzles like a robot?
                    </a>{' '}
                    <br />
                    Do you think you're a puzzle master? Do you think that you can do as well as a
                    computer program? Try our puzzles to find out. They take about 10 minutes.
                </p>
            </ExperimentContainer>
        </React.Fragment>
    );
};

const RobotGrid = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    margin: auto;
    grid-gap: ${({ theme }) => `${theme.spacing.lg}`};
    width: fit-content;
    margin-top: ${({ theme }) => `${theme.spacing.xl2}`};
`;

const ExperimentContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: ${({ theme }) => `${theme.spacing.md}`};

    img {
        width: 150px;
        margin: auto;
    }
`;

const ShareResults = styled.div`
    svg {
        height: 50px;
        width: 50px;
        margin-left: ${({ theme }) => `${theme.spacing.sm}`};
    }
`;

const PlayAgainContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${({ theme }) => `${theme.spacing.md}`};
    margin-bottom: ${({ theme }) => `${theme.spacing.xl3}`};

    @media ${({ theme }) => belowOrEqualTo(theme.breakpoints.md)} {
        grid-template-columns: 1fr;
    }
`;
