import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Button from 'antd/es/button';
import Divider from 'antd/es/divider';
import Modal from 'antd/es/modal';
import {
    LoadingOutlined,
    ShareAltOutlined,
    FacebookFilled,
    LinkedinFilled,
    TwitterSquareFilled,
} from '@ant-design/icons';
import { textStyles, belowOrEqualTo, Theme } from '@allenai/varnish';

import { NextButton, StyledProgress } from '.';
import {
    UserDemographics,
    getReport,
    SessionReport,
    getReportFromEncryptedSessionId,
} from '../api';
import { Visualization } from './Visualization';

const { Big, Jumbo } = textStyles;

interface Props {
    onNextButtonClick?: () => void;
    demographics?: UserDemographics | undefined;
    sessionId?: number;
    shortUuid?: string;
}

const moralFoundationsFormatMap: any = {
    none: {
        text: '🗓️ everyday',
        order: 5,
        definition: (
            <React.Fragment>
                <b>🗓️ Everyday</b> refers to everyday situations which have no moral implications.
            </React.Fragment>
        ),
    },
    'sanctity-degradation': {
        text: '🌸 sanctity',
        order: 4,
        definition: (
            <React.Fragment>
                <b>🌸 Sanctity/degredation </b> relates to morals of living in an elevated and noble
                manner.
            </React.Fragment>
        ),
    },
    'authority-subversion': {
        text: '👑 authority',
        order: 3,
        definition: (
            <React.Fragment>
                <b>👑 Authority/subversion</b> is morals based on social hierarchies (e.g., valuing
                leadership).
            </React.Fragment>
        ),
    },
    'loyalty-betrayal': {
        text: '🤝 loyalty',
        order: 2,
        definition: (
            <React.Fragment>
                <b>🤝 Loyalty/betrayal</b> is morals from building alliances (e.g., valuing
                patriotism).
            </React.Fragment>
        ),
    },
    'fairness-cheating': {
        text: '⚖️ fairness',
        order: 1,
        definition: (
            <React.Fragment>
                <b>⚖️ Fairness/cheating</b> relates to morals from reciprocated altruism (e.g.,
                valuing justice).
            </React.Fragment>
        ),
    },
    'care-harm': {
        text: '💞 care',
        order: 0,
        definition: (
            <React.Fragment>
                <b>💞 Care/harm</b> is morals of having empathy towards the pain of others (e.g.,
                valuing kindess).
            </React.Fragment>
        ),
    },
};

const demographicsFormatMap: any = {
    countryLivedInLongest: {
        text: 'Country You Lived in Longest',
        order: 0,
        color: Theme.color.R8.hex,
    },
    countryOfResidence: {
        text: 'Your Country of Residence',
        order: 1,
        color: Theme.color.G8.hex,
    },
    age: {
        text: 'Your Age',
        order: 2,
        color: Theme.color.P4.hex,
    },
    nativeLanguage: {
        text: 'Your Native Language',
        order: 3,
        color: Theme.color.O8.hex,
    },
    religion: {
        text: 'Your Religion',
        order: 4,
        color: Theme.color.M8.hex,
    },
    educationLevel: {
        text: 'Your Education Level',
        order: 5,
        color: Theme.color.P8.hex,
    },
    ethnicities: {
        text: 'Your Ethnicity',
        order: 6,
        color: Theme.color.O4.hex,
    },
    gender: {
        text: 'Your Gender',
        order: 7,
        color: Theme.color.N8.hex,
    },
};

export const Report = (props: Props) => {
    const [report, setReport] = useState<SessionReport>();
    const [isReportLoading, setIsReportLoading] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);

    useEffect(() => {
        // Double check if you are coming from the Report route
        if (!props.sessionId && props.shortUuid) {
            setIsReportLoading(true);
            getReportFromEncryptedSessionId(props.shortUuid)
                .then((reportData) => {
                    setIsReportLoading(false);
                    setReport(reportData);
                })
                .catch(() => {
                    setIsReportLoading(false);
                    alert('Could not fetch your report. Please try again.');
                });
        } else if (props.sessionId && props.demographics) {
            setIsReportLoading(true);
            getReport(props.sessionId, props.demographics)
                .then((reportData) => {
                    setIsReportLoading(false);
                    setReport(reportData);
                })
                .catch(() => {
                    setIsReportLoading(false);
                    alert('Could not fetch your report. Please try again.');
                });
        }
    }, ['']);

    return (
        <React.Fragment>
            <HeaderGrid>
                <h2 style={{ marginTop: '0' }}>Results</h2>
                <ShareButton type="primary" onClick={() => setShowModal(true)}>
                    <ShareAltOutlined />
                    Share
                </ShareButton>
            </HeaderGrid>
            {report && !isReportLoading ? (
                <React.Fragment>
                    <p>
                        <Big>Here's how often your responses matched with the AI's.</Big>
                    </p>
                    <YourMachResults>
                        <h3> You + AI are a... </h3>
                        {/* <p>{Math.round(report.aiReport.matchScore * 100)}% match</p> */}
                        <StyledProgress
                            type="circle"
                            format={(percent: any) => percent + '%' + '\n' + 'match'}
                            percent={Math.round(report.aiReport.matchScore * 100)}
                            trailColor={'white'}
                        />
                    </YourMachResults>
                    <p>
                        {' '}
                        <b>
                            <Jumbo>People from the same demographic as you scored...</Jumbo>
                        </b>
                    </p>
                    <MatchScoreGrid>
                        {Object.keys(report.othersReport).map((key) => {
                            const data = (report.othersReport as any)[key];
                            return (
                                <MatchScoreDiv>
                                    <StyledProgress
                                        customWidth={'175px'}
                                        type="circle"
                                        format={(percent: any) => percent + '%' + '\n' + 'match'}
                                        percent={Math.round(data.matchScore * 100)}
                                        strokeColor={demographicsFormatMap[key].color}
                                    />
                                    <p>
                                        {' '}
                                        <b>{demographicsFormatMap[key].text} + AI</b>
                                    </p>
                                </MatchScoreDiv>
                            );
                        })}
                    </MatchScoreGrid>
                    <Divider />
                    <h3>When did you agree with the AI?</h3>
                    <p>
                        <Big>
                            Here's a breakdown of your match score with the AI by the type of moral
                            situation.{' '}
                            <b style={{ textDecoration: 'underline' }}>
                                Higher values indicate higher agreement with the AI.
                            </b>
                        </Big>
                    </p>
                    <p>
                        <Jumbo>
                            <b>Types of moral situations</b>
                        </Jumbo>
                        <br />
                        <Big>
                            {Object.keys(report.aiReport.report)
                                .sort(
                                    (a, b) =>
                                        moralFoundationsFormatMap[a].order -
                                        moralFoundationsFormatMap[b].order
                                )
                                .map((key) => {
                                    return (
                                        <React.Fragment>
                                            {moralFoundationsFormatMap[key].definition}
                                            <br />
                                        </React.Fragment>
                                    );
                                })}
                        </Big>
                    </p>
                    <ResultsContainer>
                        <YourResults>
                            <h5>Agreement of</h5>
                            <h4>
                                {' '}
                                You + AI{' '}
                                <span>
                                    ({Math.round(report.aiReport.matchScore * 100)}% match)
                                </span>{' '}
                            </h4>
                            <Visualization
                                report={report.aiReport.report}
                                formatMap={moralFoundationsFormatMap}
                            />
                        </YourResults>
                    </ResultsContainer>
                    <ResultsContainer>
                        {Object.keys(report.othersReport).map((key) => {
                            const matchScore = Math.round(
                                (report.othersReport as any)[key].matchScore * 100
                            );
                            return (
                                <div>
                                    <h5>Agreement of</h5>
                                    <h4>
                                        {' '}
                                        {demographicsFormatMap[key].text} + AI{' '}
                                        <span>({matchScore}% match)</span>
                                    </h4>
                                    <Visualization
                                        report={report.aiReport.report}
                                        otherReport={(report.othersReport as any)[key].report}
                                        otherColor={demographicsFormatMap[key].color}
                                        formatMap={moralFoundationsFormatMap}
                                    />
                                </div>
                            );
                        })}
                    </ResultsContainer>
                    {props.onNextButtonClick && <NextButton onClick={props.onNextButtonClick} />}
                </React.Fragment>
            ) : isReportLoading ? (
                <React.Fragment>
                    {' '}
                    Loading <LoadingOutlined />{' '}
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <p>
                        <Big>This report does not exist!</Big>
                    </p>
                </React.Fragment>
            )}
            <Modal
                title="Share your results"
                visible={showModal}
                onOk={() => setShowModal(false)}
                onCancel={() => setShowModal(false)}
                footer={null}>
                <ShareResults style={{ textAlign: 'center' }}>
                    <Jumbo style={{ display: 'block' }}>
                        <b>Share the study with your friends:</b>
                    </Jumbo>
                    <Jumbo>
                        <a
                            href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdelphi-litw.apps.allenai.org"
                            target="_blank"
                            rel="noreferrer">
                            <FacebookFilled />
                        </a>
                        <a
                            href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fdelphi-litw.apps.allenai.org%2F&text=Tell%20us%20what%20you%20believe%20about%20certain%20situations%20and%20we%20will%20show%20you%20how%20you%20compare%20to%20others%E2%80%99%20and%20an%20AI.%0A"
                            target="_blank"
                            rel="noreferrer">
                            <TwitterSquareFilled />
                        </a>
                        <a
                            href="http://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fdelphi-litw.apps.allenai.org%2F&title=Could%20you%20live%20with%20an%20AI%20and%20its%20morals%3F%0A&summary=Tell%20us%20what%20you%20believe%20about%20certain%20situations%20and%20we%20will%20show%20you%20how%20you%20compare%20to%20others%E2%80%99%20and%20an%20AI.%0A"
                            target="_blank noreferrer">
                            <LinkedinFilled />
                        </a>
                    </Jumbo>
                </ShareResults>
                <ShareResults style={{ textAlign: 'center', marginTop: '16px' }}>
                    <Jumbo style={{ display: 'block' }}>
                        <b>Link to your results:</b>
                    </Jumbo>
                    {props.shortUuid ? (
                        <p>
                            <Big>
                                <a
                                    href={
                                        'https://delphi-litw.apps.allenai.org/report/' +
                                        props.shortUuid
                                    }
                                    target="_blank"
                                    rel="noreferrer">
                                    https://delphi-litw.apps.allenai.org/report/
                                    {props.shortUuid}
                                </a>
                            </Big>
                        </p>
                    ) : null}
                </ShareResults>
            </Modal>
        </React.Fragment>
    );
};

const YourMachResults = styled.div`
    h3 {
        text-align: center;
        margin-top: 0;
    }
    p {
        font-size: 72px;
        font-style: italic;
        text-align: center;
        color: ${({ theme }) => `${theme.color.B6}`};
    }
    margin: ${({ theme }) =>
        `${theme.spacing.md} ${theme.spacing.md} ${theme.spacing.xl} ${theme.spacing.md}`};
    padding: ${({ theme }) => `${theme.spacing.lg}`};
    border: ${({ theme }) => `1px solid ${theme.color.B3}`};
    background-color: ${({ theme }) => `${theme.color.B1}`};
    border-radius: 4px;
`;

const YourResults = styled.div``;

const MatchScoreDiv = styled.div`
    p {
        text-align: center;
        margin: ${({ theme }) => `${theme.spacing.md}`};
        font-size: 18px;
    }
`;

const MatchScoreGrid = styled.div`
    margin: auto;
    display: grid;
    grid-gap: ${({ theme }) => `${theme.spacing.lg}`};
    padding: ${({ theme }) => `${theme.spacing.xs}`};
    border-radius: 4px;
    grid-template-columns: repeat(3, 1fr);

    @media ${({ theme }) => belowOrEqualTo(theme.breakpoints.lg)} {
        grid-template-columns: repeat(2, 1fr);
    }

    @media ${({ theme }) => belowOrEqualTo(theme.breakpoints.sm)} {
        grid-template-columns: 1fr;
        grid-gap: ${({ theme }) => `${theme.spacing.xl3}`};
    }
`;

const ResultsContainer = styled.div`
    display: grid;
    grid-gap: ${({ theme }) => `${theme.spacing.lg}`};
    padding: ${({ theme }) => `${theme.spacing.xs}`};
    border-radius: 4px;
    grid-template-columns: 1fr;

    > div {
        width: 550px;
        margin: auto;
        padding: ${({ theme }) => `${theme.spacing.md}`};
    }

    h4,
    h5 {
        text-align: center;
        margin-top: 0;
    }

    h4 > span {
        font-size: 0.75em;
        color: ${({ theme }) => `${theme.color.N7}`};
    }

    h5 {
        margin-bottom: ${({ theme }) => `${theme.spacing.xs}`};
    }

    @media ${({ theme }) => belowOrEqualTo(theme.breakpoints.lg)} {
        > div {
            width: 75%;
        }
    }

    @media ${({ theme }) => belowOrEqualTo(theme.breakpoints.sm)} {
        > div {
            width: 100%;
        }
    }
`;

const ShareButton = styled(Button)`
    margin-top: 0;
    margin: auto;
`;

const HeaderGrid = styled.div`
    display: grid;
    grid-gap: ${({ theme }) => `${theme.spacing.sm}`};
    grid-template-columns: 1fr auto;
`;

const ShareResults = styled.div`
    svg {
        height: 50px;
        width: 50px;
        margin-left: ${({ theme }) => `${theme.spacing.sm}`};
    }
`;
