import React from 'react';
import styled from 'styled-components';

import logoSrc from '../images/logo.png';

interface Props {
    width: string | number;
}

export const LabInTheWildImage = (props: Props) => {
    return <SizedImg width={props.width} src={logoSrc} alt="LabInTheWild logo" />;
};

const SizedImg = styled.img<{ width: string | number }>`
    margin: auto;
    width: ${({ width }) => width};
`;
