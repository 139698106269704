import React, { useState } from 'react';
import Checkbox from 'antd/es/checkbox';
import Divider from 'antd/es/divider';
import { textStyles } from '@allenai/varnish';

import { NextButton } from '.';

const { Jumbo } = textStyles;

interface Props {
    onNextButtonClick: () => void;
    numQuestions: number;
}

export const IRB = (props: Props) => {
    const [hasConsented, setHasConsented] = useState<boolean>(false);

    const handleCheckboxChange = () => {
        setHasConsented(!hasConsented);
    };

    return (
        <React.Fragment>
            <p>
                <Jumbo>
                    <i> Please read the following information carefully before proceeding. </i>
                </Jumbo>
            </p>
            <p>
                <b> Why we are doing this research: </b> The goal of this project is to collect
                diverse perspectives on moral situations to improve state-of-the-art machine
                learning models.
            </p>
            <p>
                <b> What you will have to do: </b> After filling out demographic information, you
                will be presented with
                {props.numQuestions} situations. For each situation, you will rate the situation on
                a scale of very bad to very good. You may supplement describe your judgment with a
                text description.
            </p>
            <p>
                <b> What you will get out of it: </b> You will see how your ethical judgments
                compare to an AI's and other study participants'.
            </p>
            <p>
                <b> Are there any risks: </b> We do not expect there to be risks. You may skip
                providing judgment on any situation by pressing the next button and you may withdraw
                from this study by closing the browser.
            </p>
            <p>
                <b> Privacy and data collection: </b> Your responses will be kept anonymous and will
                be stored securely on our servers. We do not store any information that could be
                used to directly establish your identity. We may securely store demographics on your
                browser, only with your consent. We do securely store your previous session
                identifiers on your browser. We may share individual data with other researchers or
                use it for future research, but there will not be a way for anybody to identify
                individual information.
            </p>
            <p>
                <b> Expected duration: </b> 7 minutes
            </p>
            <p>
                <b> Contact information: </b> If you have questions about this research, you may
                contact Professor Katharina Reinecke at Paul G. Allen Center for Computer Science
                &#38; Engineering, Box 352350, Seattle, WA 98195 or{' '}
                <a href="mailto:reinecke@cs.washington.edu">reinecke@cs.washington.edu</a>.
            </p>
            <p>
                If you have questions about your rights as a research participant, or wish to obtain
                information, ask questions or discuss any concerns about this study with someone
                other than the researcher(s), please contact the University of Washington Human
                Subjects Division at 206-543-0098 or call collect at 206-221-5940 (for international
                calls include the US Calling Code: +1-206-543-0098).
            </p>
            <Divider />
            <Checkbox value={hasConsented} onChange={handleCheckboxChange}>
                I agree to participate in this research. Participation in this research is
                voluntary, and I can stop at any time without penalty. I feel that I understand what
                I am getting into, and I know I am free to leave the experiment at any time by
                simply closing the web browser.
            </Checkbox>
            <NextButton onClick={props.onNextButtonClick} disabled={!hasConsented} />
        </React.Fragment>
    );
};
