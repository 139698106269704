import axios from 'axios';

import { Annotation, InstanceReport } from '.';

export function createAnnotation(
    sessionId: number,
    instanceId: number,
    country: string,
    annotation: Annotation
): Promise<InstanceReport> {
    const payload = {
        sessionId: sessionId,
        instanceId: instanceId,
        country: country,
        annotation: annotation,
    };
    return axios.post<InstanceReport>('/api/annotation', payload).then((resp) => resp.data);
}
