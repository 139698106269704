/**
 * This file is meant for shared display components that you use throughout
 * your application.
 *
 * Components with a lot of logic, or those that are particularly complicated
 * should probably be put in their own file. This file is meant for the
 * re-usable, simple things used in a lot of different spots in your UI.
 */
import React from 'react';
import styled from 'styled-components';
import Form from 'antd/es/form';
import Progress from 'antd/es/progress';
import { belowOrEqualTo } from '@allenai/varnish';
import WarningOutlined from '@ant-design/icons/lib/icons/WarningOutlined';

export const Error = ({ message }: { message: string }) => (
    <ErrorGrid>
        <WarningOutlined />
        {message}
    </ErrorGrid>
);

export const StyledProgress = styled(Progress)<{ customWidth?: string }>`
    &&& {
        margin: auto;
        display: block;

        .ant-progress-inner {
            margin: auto;
            display: block;
            height: ${({ customWidth }) => `${customWidth || '250px'} !important`};
            width: ${({ customWidth }) => `${customWidth || '250px'} !important`};
        }
    }
`;

const TwoColumnGrid = styled.div`
    display: grid;
    grid-template-columns: auto max-content;
    grid-gap: ${({ theme }) => `${theme.spacing.xs}`};
    align-items: center;
`;

const ErrorGrid = styled(TwoColumnGrid)`
    color: ${({ theme }) => theme.palette.text.error.hex};
`;

export const CustomForm = styled(Form)`
    &&& {
        label::before {
            color: red;
            font-weight: 900;
        }

        label::after {
            content: ' ';
        }

        [for='demographics_text'] {
            margin-top: ${({ theme }) => `${theme.spacing.lg}`};
        }
    }

    @media ${({ theme }) => belowOrEqualTo(theme.breakpoints.lg)} {
        .ant-col-10,
        .ant-col-12,
        .ant-col-14 {
            flex: 0 0 100%;
            max-width: 100%;
            text-align: left;
        }

        .ant-col-offset-10 {
            margin-left: 0;
        }

        [for='demographics_text'] {
            margin-top: 0;
        }
    }
`;

export const Why = styled.b`
    :hover {
        cursor: pointer;
    }
    text-decoration: underline;
    color: #265ed4;
`;
