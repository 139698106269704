import React, { useState } from 'react';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Radio from 'antd/es/radio';
import { LoadingOutlined } from '@ant-design/icons';
import { textStyles } from '@allenai/varnish';

import { NextButton, CustomForm } from '.';
import { createFeedback, SessionFeedback } from '../api';

const { Big } = textStyles;

interface Props {
    onNextButtonClick: () => void;
    sessionId?: number;
}

export const Feedback = (props: Props) => {
    const [feedback, setFeedback] = useState<SessionFeedback>({
        feedbackDescription: undefined,
        hasTechnicalDifficulties: undefined,
        technicalDifficultiesDescription: undefined,
        hasCheated: undefined,
        cheatedDescription: undefined,
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [form] = Form.useForm();

    const submitFeedback = () => {
        const hasFeedback =
            feedback.feedbackDescription !== undefined ||
            feedback.hasTechnicalDifficulties !== undefined ||
            feedback.hasCheated !== undefined;
        if (props.sessionId !== undefined && hasFeedback) {
            setIsLoading(true);
            createFeedback(props.sessionId, feedback)
                .then(() => {
                    setIsLoading(false);
                    props.onNextButtonClick();
                })
                .catch(() => {
                    setIsLoading(false);
                    alert('Could not submit the feedback! Please try again.');
                });
        } else {
            props.onNextButtonClick();
        }
    };

    return (
        <React.Fragment>
            <h2 style={{ marginTop: '0' }}> Thank you for participating! </h2>
            <p>
                <Big>
                    Before seeing your results, please let us know what you thought of the test.
                </Big>
            </p>
            {/* <p style={{ textAlign: 'center', marginTop: '36px' }}>
                <i>
                    <span style={{ color: 'red' }}>*</span> denotes a required field.
                </i>{' '}
            </p> */}
            <CustomForm
                form={form}
                name="study-feedback"
                labelCol={{ span: 14 }}
                wrapperCol={{ span: 10 }}>
                <Form.Item
                    name="feedback"
                    label="Do you have any feedback for the researcher? Any questions, suggestions, or concerns?">
                    <Input.TextArea
                        placeholder="Enter your feedback, if you have any"
                        onChange={(event: any) => {
                            setFeedback({
                                feedbackDescription: event.target.value,
                                hasTechnicalDifficulties: feedback.hasTechnicalDifficulties,
                                technicalDifficultiesDescription:
                                    feedback.technicalDifficultiesDescription,
                                hasCheated: feedback.hasCheated,
                                cheatedDescription: feedback.cheatedDescription,
                            });
                        }}
                    />
                </Form.Item>
                <Form.Item
                    name="hasTechnicalDifficulties"
                    label="Did you encounter any technical difficulties during the study?"
                    // rules={[{ required: true }]}
                >
                    <Radio.Group
                        optionType="button"
                        buttonStyle="solid"
                        onChange={(event: any) => {
                            setFeedback({
                                feedbackDescription: feedback.feedbackDescription,
                                hasTechnicalDifficulties: event.target.value === '1',
                                technicalDifficultiesDescription:
                                    feedback.technicalDifficultiesDescription,
                                hasCheated: feedback.hasCheated,
                                cheatedDescription: feedback.cheatedDescription,
                            });
                        }}>
                        <Radio.Button value="1">Yes</Radio.Button>
                        <Radio.Button value="0">No</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.hasTechnicalDifficulties !==
                        currentValues.hasTechnicalDifficulties
                    }>
                    {({ getFieldValue }) =>
                        getFieldValue('hasTechnicalDifficulties') === '1' ? (
                            <Form.Item
                                name="technicalDifficultiesDescription"
                                label="Describe how you faced technical difficulties">
                                <Input.TextArea
                                    placeholder="Enter your technical difficulties"
                                    onChange={(event: any) => {
                                        setFeedback({
                                            feedbackDescription: feedback.feedbackDescription,
                                            hasTechnicalDifficulties:
                                                feedback.hasTechnicalDifficulties,
                                            technicalDifficultiesDescription: event.target.value,
                                            hasCheated: feedback.hasCheated,
                                            cheatedDescription: feedback.cheatedDescription,
                                        });
                                    }}
                                />
                            </Form.Item>
                        ) : null
                    }
                </Form.Item>
                <Form.Item
                    name="hasCheated"
                    label="Did you in any way provide false information?"
                    // rules={[{ required: true }]}
                >
                    <Radio.Group
                        optionType="button"
                        buttonStyle="solid"
                        onChange={(event: any) => {
                            setFeedback({
                                feedbackDescription: feedback.feedbackDescription,
                                hasTechnicalDifficulties: feedback.hasTechnicalDifficulties,
                                technicalDifficultiesDescription:
                                    feedback.technicalDifficultiesDescription,
                                hasCheated: event.target.value === '1',
                                cheatedDescription: feedback.cheatedDescription,
                            });
                        }}>
                        <Radio.Button value="1">Yes</Radio.Button>
                        <Radio.Button value="0">No</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.hasCheated !== currentValues.hasCheated
                    }>
                    {({ getFieldValue }) =>
                        getFieldValue('hasCheated') === '1' ? (
                            <Form.Item
                                name="cheatedDescription"
                                label="Describe how you provided false information">
                                <Input.TextArea
                                    placeholder="Enter how you provided false information"
                                    onChange={(event: any) => {
                                        setFeedback({
                                            feedbackDescription: feedback.feedbackDescription,
                                            hasTechnicalDifficulties:
                                                feedback.hasTechnicalDifficulties,
                                            technicalDifficultiesDescription:
                                                feedback.technicalDifficultiesDescription,
                                            hasCheated: feedback.hasCheated,
                                            cheatedDescription: event.target.value,
                                        });
                                    }}
                                />
                            </Form.Item>
                        ) : null
                    }
                </Form.Item>
            </CustomForm>
            <NextButton
                onClick={submitFeedback}
                message={
                    isLoading ? (
                        <React.Fragment>
                            {' '}
                            Loading <LoadingOutlined />{' '}
                        </React.Fragment>
                    ) : undefined
                }
                disabled={
                    // feedback.hasTechnicalDifficulties === undefined ||
                    // feedback.hasCheated === undefined ||
                    isLoading
                }
            />
        </React.Fragment>
    );
};
