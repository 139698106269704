import React from 'react';
import { VictoryArea, VictoryPolarAxis, VictoryChart, VictoryTheme } from 'victory';
import { Theme } from '@allenai/varnish';

import { AiReport } from '../api';

interface Props {
    report: AiReport;
    formatMap: any;
    color?: string;
    otherReport?: AiReport;
    otherColor?: string;
}

export const RadarVisualization = (props: Props) => {
    return (
        <VictoryChart polar theme={VictoryTheme.material}>
            <VictoryArea
                data={
                    props.report !== undefined && props.report !== undefined
                        ? Object.keys(props.report).map((key) => {
                              const agree = (props.report as any)[key].agree;
                              const disagree = (props.report as any)[key].disagree;
                              const ret = {
                                  x: props.formatMap[key].text,
                                  y: agree !== 0 ? agree / (agree + disagree) : 0.1,
                              };
                              return ret;
                          })
                        : []
                }
                style={{
                    data: {
                        fillOpacity: 0.2,
                        strokeWidth: 1,
                        fill: props.color ? props.color : 'blue',
                    },
                }}
            />
            {props.otherReport && (
                <VictoryArea
                    data={
                        props.otherReport !== undefined && props.otherReport !== undefined
                            ? Object.keys(props.otherReport).map((key) => {
                                  const agree = (props.otherReport as any)[key].agree;
                                  const disagree = (props.otherReport as any)[key].disagree;
                                  const ret = {
                                      x: props.formatMap[key].text,
                                      y: agree !== 0 ? agree / (agree + disagree) : 0.1,
                                  };
                                  return ret;
                              })
                            : []
                    }
                    style={{
                        data: {
                            fillOpacity: 0.2,
                            strokeWidth: 1,
                            fill: props.otherColor ? props.otherColor : 'blue',
                        },
                    }}
                />
            )}
            {Object.keys(props.report)
                .map((key) => (props.formatMap as any)[key].text)
                .map((d, i) => {
                    return (
                        <VictoryPolarAxis
                            dependentAxis
                            key={i}
                            label={d}
                            labelPlacement="perpendicular"
                            style={{
                                tickLabels: {
                                    display: 'none',
                                },
                                // axis: { stroke: "none" },
                                grid: {
                                    stroke: Theme.color.N8.hex,
                                    strokeWidth: 0.25,
                                    opacity: 0.5,
                                },
                                axisLabel: {
                                    fontSize: 15,
                                    padding: 15,
                                },
                            }}
                            axisValue={d}
                            tickValues={[0.0, 0.25, 0.5, 0.75, 1.0]}
                        />
                    );
                })}
        </VictoryChart>
    );
};
