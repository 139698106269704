import React from 'react';
import styled from 'styled-components';
import { textStyles } from '@allenai/varnish';

const { Big } = textStyles;

interface Props {
    text: React.ReactFragment;
}

export const SpeechBubble = (props: Props) => {
    return (
        <SpeechBubbleContainer>
            <Big>{props.text}</Big>
        </SpeechBubbleContainer>
    );
};

// Speech bubble code from https://code-boxx.com/responsive-css-speech-bubbles/
const SpeechBubbleContainer = styled.div`
    position: relative;
    height: fit-content;
    max-width: 320px;
    background-color: ${({ theme }) => `${theme.color.B2}`};
    border-radius: 4px;
    padding: ${({ theme }) => `${theme.spacing.md}`};

    ::after {
        position: absolute;
        content: '';
        border: 20px solid transparent;
        border-right-color: ${({ theme }) => `${theme.color.B2}`};
        border-left: 0;
        left: -20px;
        top: 50%;
        margin-top: -20px;
    }
`;
