import React from 'react';
import styled from 'styled-components';
import Checkbox from 'antd/es/checkbox';
import Col from 'antd/es/col';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import InputNumber from 'antd/es/input-number';
import Radio from 'antd/es/radio';
import Row from 'antd/es/row';
import Select from 'antd/es/select';

import { CustomForm } from '.';
import { UserDemographics } from '../api';

const { Option } = Select;

const languages = [
    { key: 'Add a language', value: 'addLanguage' },
    { key: 'Afrikaans', value: 'Afrikaans' },
    { key: 'Albanian', value: 'Albanian' },
    { key: 'Arabic', value: 'Arabic' },
    { key: 'Armenian', value: 'Armenian' },
    { key: 'Basque', value: 'Basque' },
    { key: 'Bengali', value: 'Bengali' },
    { key: 'Bulgarian', value: 'Bulgarian' },
    { key: 'Catalan', value: 'Catalan' },
    { key: 'Cambodian', value: 'Cambodian' },
    { key: 'Chinese (Mandarin)', value: 'Chinese (Mandarin)' },
    { key: 'Croatian', value: 'Croatian' },
    { key: 'Czech', value: 'Czech' },
    { key: 'Danish', value: 'Danish' },
    { key: 'Dutch', value: 'Dutch' },
    { key: 'English', value: 'English' },
    { key: 'Estonian', value: 'Estonian' },
    { key: 'Finnish', value: 'Finnish' },
    { key: 'French', value: 'French' },
    { key: 'Georgian', value: 'Georgian' },
    { key: 'German', value: 'German' },
    { key: 'Greek', value: 'Greek' },
    { key: 'Gujarati', value: 'Gujarati' },
    { key: 'Hebrew', value: 'Hebrew' },
    { key: 'Hindi', value: 'Hindi' },
    { key: 'Hungarian', value: 'Hungarian' },
    { key: 'Icelandic', value: 'Icelandic' },
    { key: 'Indonesian', value: 'Indonesian' },
    { key: 'Irish', value: 'Irish' },
    { key: 'Italian', value: 'Italian' },
    { key: 'Japanese', value: 'Japanese' },
    { key: 'Javanese', value: 'Javanese' },
    { key: 'Korean', value: 'Korean' },
    { key: 'Latin', value: 'Latin' },
    { key: 'Latvian', value: 'Latvian' },
    { key: 'Lithuanian', value: 'Lithuanian' },
    { key: 'Macedonian', value: 'Macedonian' },
    { key: 'Malay', value: 'Malay' },
    { key: 'Malayalam', value: 'Malayalam' },
    { key: 'Maltese', value: 'Maltese' },
    { key: 'Maori', value: 'Maori' },
    { key: 'Marathi', value: 'Marathi' },
    { key: 'Mongolian', value: 'Mongolian' },
    { key: 'Nepali', value: 'Nepali' },
    { key: 'Norwegian', value: 'Norwegian' },
    { key: 'Persian', value: 'Persian' },
    { key: 'Polish', value: 'Polish' },
    { key: 'Portuguese', value: 'Portuguese' },
    { key: 'Punjabi', value: 'Punjabi' },
    { key: 'Quechua', value: 'Quechua' },
    { key: 'Romanian', value: 'Romanian' },
    { key: 'Russian', value: 'Russian' },
    { key: 'Samoan', value: 'Samoan' },
    { key: 'Serbian', value: 'Serbian' },
    { key: 'Slovak', value: 'Slovak' },
    { key: 'Slovenian', value: 'Slovenian' },
    { key: 'Spanish', value: 'Spanish' },
    { key: 'Swahili', value: 'Swahili' },
    { key: 'Swedish', value: 'Swedish' },
    { key: 'Tamil', value: 'Tamil' },
    { key: 'Tatar', value: 'Tatar' },
    { key: 'Telugu', value: 'Telugu' },
    { key: 'Thai', value: 'Thai' },
    { key: 'Tibetan', value: 'Tibetan' },
    { key: 'Tonga', value: 'Tonga' },
    { key: 'Turkish', value: 'Turkish' },
    { key: 'Ukrainian', value: 'Ukrainian' },
    { key: 'Urdu', value: 'Urdu' },
    { key: 'Uzbek', value: 'Uzbek' },
    { key: 'Vietnamese', value: 'Vietnamese' },
    { key: 'Welsh', value: 'Welsh' },
    { key: 'Xhosa', value: 'Xhosa' },
    { key: 'Prefer not to answer', value: 'no response' },
];

const religions = [
    { key: 'I do not belong to a religion or religious denomination', value: 'none' },
    { key: 'Buddhist', value: 'buddhist' },
    { key: 'Hindu', value: 'hindu' },
    { key: 'Jew', value: 'jew' },
    { key: 'Muslim', value: 'muslim' },
    { key: 'Orthodox (Russian/Greek/etc.)', value: 'orthodox' },
    { key: 'Protestant', value: 'protestant' },
    { key: 'Roman Catholic', value: 'roman catholic' },
    { key: 'Prefer to self-describe', value: 'self-describe' },
    { key: 'Prefer not to answer', value: 'no response' },
];

const genders = [
    { key: 'Man', value: 'man' },
    { key: 'Woman', value: 'woman' },
    { key: 'Non-binary', value: 'non-binary' },
    { key: 'Prefer to self-describe', value: 'self-describe' },
    { key: 'Prefer not to say', value: 'no response' },
];

const ethnicities = [
    'white',
    'pacific islander, native australian',
    'native american, american indian, alaska native',
    'latino/latina, hispanic',
    'black, african american',
    'asian, asian american',
    'no response',
    'self-describe',
];

interface Props {
    isLoading: boolean;
    updateDemographics: (key: string, value: any) => void;
    demographics: UserDemographics;
    isModalMode?: boolean;
}

export const DemographicsForm = (props: Props) => {
    const [form] = Form.useForm();

    const showSaveDemographicsRationale = () => {
        const alertMessage =
            "We can store your demographics information if you are on a personal computer. This will allow you to skip this form if you take this test again. If you'd prefer not to have a saved copy, leave the box unchecked.";
        alert(alertMessage);
    };

    const showCustomNativeLanguage =
        props.demographics.nativeLanguage !== undefined &&
        !languages.map((obj) => obj.value).includes(props.demographics.nativeLanguage);

    const showCustomReligion =
        props.demographics.religion !== undefined &&
        !religions.map((obj) => obj.value).includes(props.demographics.religion);

    const showCustomGender =
        props.demographics.gender !== undefined &&
        !genders.map((obj) => obj.value).includes(props.demographics.gender);

    const customEthnicities =
        props.demographics.ethnicities !== undefined
            ? props.demographics.ethnicities.filter(
                  (ethnicity: string) => !ethnicities.includes(ethnicity)
              )
            : undefined;

    const showCustomEthnicities = customEthnicities && customEthnicities.length > 0;

    // Set form values
    form.setFieldsValue({
        countryLivedInLongest: props.demographics.countryLivedInLongest,
        countryOfResidence: props.demographics.countryOfResidence,
        ethnicities: props.demographics.ethnicities,
        gender: props.demographics.gender,
        nativeLanguage: props.demographics.nativeLanguage,
        religion: props.demographics.religion,
        educationLevel: props.demographics.educationLevel,
    });

    if (props.demographics.hasTakenTest !== undefined) {
        form.setFieldsValue({ hasTakenTest: props.demographics.hasTakenTest ? 'yes' : 'no' });
    }

    if (showCustomNativeLanguage) {
        form.setFieldsValue({ nativeLanguage: 'addLanguage' });
        form.setFieldsValue({ nativeLanguageSelfDescribed: props.demographics.nativeLanguage });
    }

    if (showCustomReligion) {
        form.setFieldsValue({ religion: 'self-describe' });
        form.setFieldsValue({ religionSelfDescribed: props.demographics.religion });
    }

    if (showCustomGender) {
        form.setFieldsValue({ gender: 'self-describe' });
        form.setFieldsValue({ genderSelfDescribed: props.demographics.gender });
    }

    if (showCustomEthnicities) {
        const customEthnicity = customEthnicities[0];
        const ethnicitiesCopy = props.demographics.ethnicities
            ? Array.from(props.demographics.ethnicities).filter(
                  (element) => element !== customEthnicity
              )
            : [];
        ethnicitiesCopy.push('self-describe');

        form.setFieldsValue({ ethnicities: ethnicitiesCopy });
        form.setFieldsValue({ ethnicitySelfDescribed: customEthnicity });
    }

    return (
        <React.Fragment>
            <CustomForm
                form={form}
                name="demographics"
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}>
                {!props.isModalMode && (
                    <Form.Item
                        name="hasTakenTest"
                        label="Have you taken this test before?"
                        rules={[{ required: true }]}>
                        <Radio.Group
                            value={props.demographics.hasTakenTest ? 'yes' : 'no'}
                            optionType="button"
                            buttonStyle="solid"
                            disabled={props.isLoading}
                            onChange={(event: any) =>
                                props.updateDemographics('hasTakenTest', event.target.value)
                            }>
                            <Radio.Button value="yes">Yes</Radio.Button>
                            <Radio.Button value="no">No</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                )}
                <Form.Item
                    name="countryLivedInLongest"
                    label="Which country did you live in the longest growing up?"
                    rules={[{ required: true }]}>
                    <CountrySelect
                        disabled={
                            props.isLoading ||
                            ((props.isModalMode &&
                                props.demographics.countryLivedInLongest) as boolean)
                        }
                        onChange={(value: any) =>
                            props.updateDemographics('countryLivedInLongest', value)
                        }
                        value={props.demographics.countryLivedInLongest}
                    />
                </Form.Item>
                <Form.Item
                    name="countryOfResidence"
                    label="Which country do you currently live in?">
                    <CountrySelect
                        disabled={
                            props.isLoading ||
                            ((props.isModalMode &&
                                props.demographics.countryOfResidence) as boolean)
                        }
                        onChange={(value: any) =>
                            props.updateDemographics('countryOfResidence', value)
                        }
                        value={props.demographics.countryOfResidence}
                    />
                </Form.Item>
                <Form.Item name="age" label="How old are you?" rules={[{ type: 'number', min: 1 }]}>
                    <InputNumber
                        onChange={(value: number) => {
                            props.updateDemographics('age', value);
                        }}
                        value={props.demographics.age}
                    />
                </Form.Item>
                <Form.Item
                    name="nativeLanguage"
                    label="What is your native language? (if multiple, pick the one you speak in most often)">
                    <Select
                        value={props.demographics.nativeLanguage}
                        disabled={
                            props.isLoading ||
                            ((props.isModalMode && props.demographics.nativeLanguage) as boolean)
                        }
                        showSearch={true}
                        placeholder="Enter your native language"
                        allowClear
                        onChange={(value: any) =>
                            props.updateDemographics('nativeLanguage', value)
                        }>
                        {languages.map((data: { key: string; value: string }) => (
                            <Option value={data.value} key={data.key}>
                                {data.key}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.language !== currentValues.language
                    }>
                    {({ getFieldValue }) =>
                        getFieldValue('nativeLanguage') === 'addLanguage' ||
                        showCustomNativeLanguage ? (
                            <Form.Item
                                name="nativeLanguageSelfDescribed"
                                label="Please enter your native language">
                                <Input
                                    disabled={
                                        props.isLoading ||
                                        ((props.isModalMode &&
                                            props.demographics.nativeLanguage) as boolean)
                                    }
                                    placeholder="Enter your native language"
                                    onChange={(event: any) =>
                                        props.updateDemographics(
                                            'nativeLanguage',
                                            event.target.value
                                        )
                                    }
                                />
                            </Form.Item>
                        ) : null
                    }
                </Form.Item>
                <Form.Item
                    name="religion"
                    label="What religion or religious denomination do you belong to?">
                    <Select
                        disabled={
                            props.isLoading ||
                            ((props.isModalMode && props.demographics.religion) as boolean)
                        }
                        placeholder="Select a religion or religious denomination"
                        allowClear
                        onChange={(value: any) => props.updateDemographics('religion', value)}>
                        {religions.map((data: { key: string; value: string }) => (
                            <Option value={data.value} key={data.key}>
                                {data.key}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.religion !== currentValues.religion
                    }>
                    {({ getFieldValue }) =>
                        getFieldValue('religion') === 'self-describe' || showCustomReligion ? (
                            <Form.Item
                                name="religionSelfDescribed"
                                label="Please describe your religion or religious denomination">
                                <Input
                                    disabled={
                                        props.isLoading ||
                                        ((props.isModalMode &&
                                            props.demographics.religion) as boolean)
                                    }
                                    placeholder="Enter your religion or religious denomination"
                                    onChange={(event: any) =>
                                        props.updateDemographics('religion', event.target.value)
                                    }
                                />
                            </Form.Item>
                        ) : null
                    }
                </Form.Item>
                <Form.Item
                    name="educationLevel"
                    label="What is the highest level of education that you have received or are pursuing?">
                    <Select
                        disabled={
                            props.isLoading ||
                            ((props.isModalMode && props.demographics.educationLevel) as boolean)
                        }
                        placeholder="Select an education level"
                        allowClear
                        onChange={(value: any) =>
                            props.updateDemographics('educationLevel', value)
                        }>
                        <Option value="pre-high school">Pre-high school</Option>
                        <Option value="high school">High school</Option>
                        <Option value="college">College</Option>
                        <Option value="graduate school">Graduate school</Option>
                        <Option value="professional school">Professional school</Option>
                        <Option value="phd">Ph.D.</Option>
                        <Option value="no response">Prefer not to answer</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.ethnicities !== currentValues.ethnicities
                    }>
                    {({ getFieldValue }) =>
                        getFieldValue('countryLivedInLongest') === 'United States' ||
                        getFieldValue('countryOfResidence') === 'United States' ? (
                            <Form.Item
                                name="ethnicities"
                                label="What is your ethnicity? (Check all that apply)">
                                <Checkbox.Group
                                    value={props.demographics.ethnicities}
                                    onChange={(values: any) =>
                                        props.updateDemographics('ethnicities', values)
                                    }>
                                    <Row>
                                        <Col span={12}>
                                            <Checkbox
                                                value="asian, asian american"
                                                disabled={
                                                    props.isLoading ||
                                                    ((props.isModalMode &&
                                                        props.demographics.ethnicities) as boolean)
                                                }>
                                                Asian or Asian American
                                            </Checkbox>
                                        </Col>
                                        <Col span={12}>
                                            <Checkbox
                                                value="black, african american"
                                                disabled={
                                                    props.isLoading ||
                                                    ((props.isModalMode &&
                                                        props.demographics.ethnicities) as boolean)
                                                }>
                                                Black or African American
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <Checkbox
                                                value="latino/latina, hispanic"
                                                disabled={
                                                    props.isLoading ||
                                                    ((props.isModalMode &&
                                                        props.demographics.ethnicities) as boolean)
                                                }>
                                                Latino / Latina or Hispanic
                                            </Checkbox>
                                        </Col>
                                        <Col span={12}>
                                            <Checkbox
                                                value="native american, american indian, alaska native"
                                                disabled={
                                                    props.isLoading ||
                                                    ((props.isModalMode &&
                                                        props.demographics.ethnicities) as boolean)
                                                }>
                                                Native American, American Indian or Alaska Native
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <Checkbox
                                                value="pacific islander, native australian"
                                                disabled={
                                                    props.isLoading ||
                                                    ((props.isModalMode &&
                                                        props.demographics.ethnicities) as boolean)
                                                }>
                                                Pacific Islander or Native Australian
                                            </Checkbox>
                                        </Col>
                                        <Col span={12}>
                                            <Checkbox
                                                value="white"
                                                disabled={
                                                    props.isLoading ||
                                                    ((props.isModalMode &&
                                                        props.demographics.ethnicities) as boolean)
                                                }>
                                                White
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <Checkbox
                                                value="self-describe"
                                                disabled={
                                                    props.isLoading ||
                                                    ((props.isModalMode &&
                                                        props.demographics.ethnicities) as boolean)
                                                }>
                                                Prefer to self-describe
                                            </Checkbox>
                                        </Col>
                                        <Col span={12}>
                                            <Checkbox
                                                value="no response"
                                                disabled={
                                                    props.isLoading ||
                                                    ((props.isModalMode &&
                                                        props.demographics.ethnicities) as boolean)
                                                }>
                                                Prefer not to answer
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                </Checkbox.Group>
                            </Form.Item>
                        ) : null
                    }
                </Form.Item>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.ethnicities !== currentValues.ethnicities
                    }>
                    {({ getFieldValue }) => {
                        const ethnicities = getFieldValue('ethnicities');
                        if (ethnicities) {
                            return ethnicities.includes('self-describe') ||
                                showCustomEthnicities ? (
                                <Form.Item
                                    name="ethnicitySelfDescribed"
                                    label="Please describe your ethnicity">
                                    <Input
                                        disabled={
                                            props.isLoading ||
                                            ((props.isModalMode &&
                                                props.demographics.ethnicities) as boolean)
                                        }
                                        placeholder="Enter your ethnicity"
                                        onChange={(event: any) =>
                                            props.updateDemographics(
                                                'ethnicitiesSelfDescribed',
                                                event.target.value
                                            )
                                        }
                                    />
                                </Form.Item>
                            ) : null;
                        }
                        return null;
                    }}
                </Form.Item>
                <Form.Item name="gender" label="What is your gender?">
                    <Select
                        value={props.demographics.gender}
                        disabled={
                            props.isLoading ||
                            ((props.isModalMode && props.demographics.gender) as boolean)
                        }
                        placeholder="Select a gender"
                        allowClear
                        onChange={(value: any) => props.updateDemographics('gender', value)}>
                        {genders.map((data: { key: string; value: string }) => (
                            <Option value={data.value} key={data.key}>
                                {data.key}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.religion !== currentValues.religion
                    }>
                    {({ getFieldValue }) =>
                        getFieldValue('gender') === 'self-describe' || showCustomGender ? (
                            <Form.Item
                                name="genderSelfDescribed"
                                label="Please describe your gender">
                                <Input
                                    disabled={
                                        props.isLoading ||
                                        ((props.isModalMode &&
                                            props.demographics.gender) as boolean)
                                    }
                                    placeholder="Enter your gender"
                                    onChange={(event: any) =>
                                        props.updateDemographics('gender', event.target.value)
                                    }
                                />
                            </Form.Item>
                        ) : null
                    }
                </Form.Item>
                {!props.isModalMode && (
                    <Form.Item
                        name="isPersonalComputer"
                        label={
                            <React.Fragment>
                                <span>
                                    Can we store your demographics on your computer?{' '}
                                    <Why onClick={showSaveDemographicsRationale}>Why?</Why>
                                </span>
                            </React.Fragment>
                        }>
                        <Radio.Group
                            disabled={props.isLoading}
                            optionType="button"
                            buttonStyle="solid"
                            onChange={(event: any) =>
                                props.updateDemographics(
                                    'isPersonalComputer',
                                    event.target.value === 'yes'
                                )
                            }>
                            <Radio.Button value="yes">Yes</Radio.Button>
                            <Radio.Button value="no">No</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                )}
            </CustomForm>
        </React.Fragment>
    );
};

const Why = styled.b`
    :hover {
        cursor: pointer;
    }
    text-decoration: underline;
    color: #265ed4;
`;

interface CountrySelectProps {
    onChange: (value: any) => void;
    disabled: boolean;
    value?: string;
}

const CountrySelect = (props: CountrySelectProps) => {
    return (
        <Select
            placeholder="Select a country"
            allowClear
            onChange={props.onChange}
            showSearch={true}
            value={props.value}
            disabled={props.disabled}>
            <Option value="Afghanistan">Afghanistan</Option>
            <Option value="Åland Islands">Åland Islands</Option>
            <Option value="Albania">Albania</Option>
            <Option value="Algeria">Algeria</Option>
            <Option value="American Samoa">American Samoa</Option>
            <Option value="Andorra">Andorra</Option>
            <Option value="Angola">Angola</Option>
            <Option value="Anguilla">Anguilla</Option>
            <Option value="Antarctica">Antarctica</Option>
            <Option value="Antigua and Barbuda">Antigua and Barbuda</Option>
            <Option value="Argentina">Argentina</Option>
            <Option value="Armenia">Armenia</Option>
            <Option value="Aruba">Aruba</Option>
            <Option value="Australia">Australia</Option>
            <Option value="Austria">Austria</Option>
            <Option value="Azerbaijan">Azerbaijan</Option>
            <Option value="Bahamas">Bahamas</Option>
            <Option value="Bahrain">Bahrain</Option>
            <Option value="Bangladesh">Bangladesh</Option>
            <Option value="Barbados">Barbados</Option>
            <Option value="Belarus">Belarus</Option>
            <Option value="Belgium">Belgium</Option>
            <Option value="Belize">Belize</Option>
            <Option value="Benin">Benin</Option>
            <Option value="Bermuda">Bermuda</Option>
            <Option value="Bhutan">Bhutan</Option>
            <Option value="Bolivia, Plurinational State of">Bolivia, Plurinational State of</Option>
            <Option value="Bonaire, Sint Eustatius and Saba">
                Bonaire, Sint Eustatius and Saba
            </Option>
            <Option value="Bosnia and Herzegovina">Bosnia and Herzegovina</Option>
            <Option value="Botswana">Botswana</Option>
            <Option value="Bouvet Island">Bouvet Island</Option>
            <Option value="Brazil">Brazil</Option>
            <Option value="British Indian Ocean Territory">British Indian Ocean Territory</Option>
            <Option value="Brunei Darussalam">Brunei Darussalam</Option>
            <Option value="Bulgaria">Bulgaria</Option>
            <Option value="Burkina Faso">Burkina Faso</Option>
            <Option value="Burundi">Burundi</Option>
            <Option value="Cambodia">Cambodia</Option>
            <Option value="Cameroon">Cameroon</Option>
            <Option value="Canada">Canada</Option>
            <Option value="Cape Verde">Cape Verde</Option>
            <Option value="Cayman Islands">Cayman Islands</Option>
            <Option value="Central African Republic">Central African Republic</Option>
            <Option value="Chad">Chad</Option>
            <Option value="Chile">Chile</Option>
            <Option value="China">China</Option>
            <Option value="Christmas Island">Christmas Island</Option>
            <Option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</Option>
            <Option value="Colombia">Colombia</Option>
            <Option value="Comoros">Comoros</Option>
            <Option value="Congo">Congo</Option>
            <Option value="Congo, the Democratic Republic of the">
                Congo, the Democratic Republic of the
            </Option>
            <Option value="Cook Islands">Cook Islands</Option>
            <Option value="Costa Rica">Costa Rica</Option>
            <Option value="Côte d'Ivoire">Côte d'Ivoire</Option>
            <Option value="Croatia">Croatia</Option>
            <Option value="Cuba">Cuba</Option>
            <Option value="Curaçao">Curaçao</Option>
            <Option value="Cyprus">Cyprus</Option>
            <Option value="Czech Republic">Czech Republic</Option>
            <Option value="Denmark">Denmark</Option>
            <Option value="Djibouti">Djibouti</Option>
            <Option value="Dominica">Dominica</Option>
            <Option value="Dominican Republic">Dominican Republic</Option>
            <Option value="Ecuador">Ecuador</Option>
            <Option value="Egypt">Egypt</Option>
            <Option value="El Salvador">El Salvador</Option>
            <Option value="Equatorial Guinea">Equatorial Guinea</Option>
            <Option value="Eritrea">Eritrea</Option>
            <Option value="Estonia">Estonia</Option>
            <Option value="Ethiopia">Ethiopia</Option>
            <Option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</Option>
            <Option value="Faroe Islands">Faroe Islands</Option>
            <Option value="Fiji">Fiji</Option>
            <Option value="Finland">Finland</Option>
            <Option value="France">France</Option>
            <Option value="French Guiana">French Guiana</Option>
            <Option value="French Polynesia">French Polynesia</Option>
            <Option value="French Southern Territories">French Southern Territories</Option>
            <Option value="Gabon">Gabon</Option>
            <Option value="Gambia">Gambia</Option>
            <Option value="Georgia">Georgia</Option>
            <Option value="Germany">Germany</Option>
            <Option value="Ghana">Ghana</Option>
            <Option value="Gibraltar">Gibraltar</Option>
            <Option value="Greece">Greece</Option>
            <Option value="Greenland">Greenland</Option>
            <Option value="Grenada">Grenada</Option>
            <Option value="Guadeloupe">Guadeloupe</Option>
            <Option value="Guam">Guam</Option>
            <Option value="Guatemala">Guatemala</Option>
            <Option value="Guernsey">Guernsey</Option>
            <Option value="Guinea">Guinea</Option>
            <Option value="Guinea-Bissau">Guinea-Bissau</Option>
            <Option value="Guyana">Guyana</Option>
            <Option value="Haiti">Haiti</Option>
            <Option value="Heard Island and McDonald Islands">
                Heard Island and McDonald Islands
            </Option>
            <Option value="Holy See (Vatican City State)">Holy See (Vatican City State)</Option>
            <Option value="Honduras">Honduras</Option>
            <Option value="Hong Kong">Hong Kong</Option>
            <Option value="Hungary">Hungary</Option>
            <Option value="Iceland">Iceland</Option>
            <Option value="India">India</Option>
            <Option value="Indonesia">Indonesia</Option>
            <Option value="Iran, Islamic Republic of">Iran, Islamic Republic of</Option>
            <Option value="Iraq">Iraq</Option>
            <Option value="Ireland">Ireland</Option>
            <Option value="Isle of Man">Isle of Man</Option>
            <Option value="Israel">Israel</Option>
            <Option value="Italy">Italy</Option>
            <Option value="Jamaica">Jamaica</Option>
            <Option value="Japan">Japan</Option>
            <Option value="Jersey">Jersey</Option>
            <Option value="Jordan">Jordan</Option>
            <Option value="Kazakhstan">Kazakhstan</Option>
            <Option value="Kenya">Kenya</Option>
            <Option value="Kiribati">Kiribati</Option>
            <Option value="Korea, Democratic People's Republic of">
                Korea, Democratic People's Republic of
            </Option>
            <Option value="Korea, Republic of">Korea, Republic of</Option>
            <Option value="Kuwait">Kuwait</Option>
            <Option value="Kyrgyzstan">Kyrgyzstan</Option>
            <Option value="Lao People's Democratic Republic">
                Lao People's Democratic Republic
            </Option>
            <Option value="LVLatvia">Latvia</Option>
            <Option value="Lebanon">Lebanon</Option>
            <Option value="Lesotho">Lesotho</Option>
            <Option value="Liberia">Liberia</Option>
            <Option value="Libya">Libya</Option>
            <Option value="Liechtenstein">Liechtenstein</Option>
            <Option value="Lithuania">Lithuania</Option>
            <Option value="Luxembourg">Luxembourg</Option>
            <Option value="Macao">Macao</Option>
            <Option value="Macedonia, the former Yugoslav Republic of">
                Macedonia, the former Yugoslav Republic of
            </Option>
            <Option value="Madagascar">Madagascar</Option>
            <Option value="Malawi">Malawi</Option>
            <Option value="Malaysia">Malaysia</Option>
            <Option value="Maldives">Maldives</Option>
            <Option value="Mali">Mali</Option>
            <Option value="Malta">Malta</Option>
            <Option value="Marshall">Marshall Islands</Option>
            <Option value="Martinique">Martinique</Option>
            <Option value="Mauritania">Mauritania</Option>
            <Option value="Mauritius">Mauritius</Option>
            <Option value="Mayotte">Mayotte</Option>
            <Option value="Mexico">Mexico</Option>
            <Option value="Micronesia, Federated States of">Micronesia, Federated States of</Option>
            <Option value="Moldova, Republic of">Moldova, Republic of</Option>
            <Option value="Monaco">Monaco</Option>
            <Option value="Mongolia">Mongolia</Option>
            <Option value="Montenegro">Montenegro</Option>
            <Option value="Montserrat">Montserrat</Option>
            <Option value="Morocco">Morocco</Option>
            <Option value="Mozambique">Mozambique</Option>
            <Option value="Myanmar">Myanmar</Option>
            <Option value="Namibia">Namibia</Option>
            <Option value="Nauru">Nauru</Option>
            <Option value="Nepal">Nepal</Option>
            <Option value="Netherlands">Netherlands</Option>
            <Option value="New Caledonia">New Caledonia</Option>
            <Option value="New Zealand">New Zealand</Option>
            <Option value="Nicaragua">Nicaragua</Option>
            <Option value="Niger">Niger</Option>
            <Option value="Nigeria">Nigeria</Option>
            <Option value="Niue">Niue</Option>
            <Option value="Norfolk Island">Norfolk Island</Option>
            <Option value="Northern Mariana Islands">Northern Mariana Islands</Option>
            <Option value="Norway">Norway</Option>
            <Option value="Oman">Oman</Option>
            <Option value="Pakistan">Pakistan</Option>
            <Option value="Palau">Palau</Option>
            <Option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</Option>
            <Option value="Panama">Panama</Option>
            <Option value="Papua New Guinea">Papua New Guinea</Option>
            <Option value="Paraguay">Paraguay</Option>
            <Option value="Peru">Peru</Option>
            <Option value="Philippines">Philippines</Option>
            <Option value="Pitcairn">Pitcairn</Option>
            <Option value="Poland">Poland</Option>
            <Option value="Portugal">Portugal</Option>
            <Option value="Puerto Rico">Puerto Rico</Option>
            <Option value="Qatar">Qatar</Option>
            <Option value="Réunion">Réunion</Option>
            <Option value="Romania">Romania</Option>
            <Option value="Russian Federation">Russian Federation</Option>
            <Option value="Rwanda">Rwanda</Option>
            <Option value="Saint Barthélemy">Saint Barthélemy</Option>
            <Option value="Saint Helena, Ascension and Tristan da Cunha">
                Saint Helena, Ascension and Tristan da Cunha
            </Option>
            <Option value="Saint Kitts and Nevis">Saint Kitts and Nevis</Option>
            <Option value="Saint Lucia">Saint Lucia</Option>
            <Option value="Saint Martin (French part)">Saint Martin (French part)</Option>
            <Option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</Option>
            <Option value="Saint Vincent and the Grenadines">
                Saint Vincent and the Grenadines
            </Option>
            <Option value="Samoa">Samoa</Option>
            <Option value="San Marino">San Marino</Option>
            <Option value="Sao Tome and Principe">Sao Tome and Principe</Option>
            <Option value="Saudi Arabia">Saudi Arabia</Option>
            <Option value="Senegal">Senegal</Option>
            <Option value="Serbia">Serbia</Option>
            <Option value="Seychelles">Seychelles</Option>
            <Option value="Sierra Leone">Sierra Leone</Option>
            <Option value="Singapore">Singapore</Option>
            <Option value="Sint Maarten (Dutch part)">Sint Maarten (Dutch part)</Option>
            <Option value="Slovakia">Slovakia</Option>
            <Option value="Slovenia">Slovenia</Option>
            <Option value="Solomon Islands">Solomon Islands</Option>
            <Option value="Somalia">Somalia</Option>
            <Option value="South Africa">South Africa</Option>
            <Option value="South Georgia and the South Sandwich Islands">
                South Georgia and the South Sandwich Islands
            </Option>
            <Option value="South Sudan">South Sudan</Option>
            <Option value="Spain">Spain</Option>
            <Option value="Sri Lanka">Sri Lanka</Option>
            <Option value="Sudan">Sudan</Option>
            <Option value="Suriname">Suriname</Option>
            <Option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</Option>
            <Option value="Swaziland">Swaziland</Option>
            <Option value="Sweden">Sweden</Option>
            <Option value="Switzerland">Switzerland</Option>
            <Option value="Syrian Arab Republic">Syrian Arab Republic</Option>
            <Option value="Taiwan, Province of China">Taiwan, Province of China</Option>
            <Option value="Tajikistan">Tajikistan</Option>
            <Option value="Tanzania, United Republic of">Tanzania, United Republic of</Option>
            <Option value="Thailand">Thailand</Option>
            <Option value="Timor-Leste">Timor-Leste</Option>
            <Option value="Togo">Togo</Option>
            <Option value="Tokelau">Tokelau</Option>
            <Option value="Tonga">Tonga</Option>
            <Option value="Trinidad and Tobago">Trinidad and Tobago</Option>
            <Option value="Tunisia">Tunisia</Option>
            <Option value="Turkey">Turkey</Option>
            <Option value="Turkmenistan">Turkmenistan</Option>
            <Option value="Turks and Caicos Islands">Turks and Caicos Islands</Option>
            <Option value="Tuvalu">Tuvalu</Option>
            <Option value="Uganda">Uganda</Option>
            <Option value="Ukraine">Ukraine</Option>
            <Option value="United Arab Emirates">United Arab Emirates</Option>
            <Option value="United Kingdom">United Kingdom</Option>
            <Option value="United States">United States</Option>
            <Option value="United States Minor Outlying Islands">
                United States Minor Outlying Islands
            </Option>
            <Option value="Uruguay">Uruguay</Option>
            <Option value="Uzbekistan">Uzbekistan</Option>
            <Option value="Vanuatu">Vanuatu</Option>
            <Option value="Venezuela, Bolivarian Republic of">
                Venezuela, Bolivarian Republic of
            </Option>
            <Option value="Viet Nam">Viet Nam</Option>
            <Option value="Virgin Islands, British">Virgin Islands, British</Option>
            <Option value="Virgin Islands, U.S.">Virgin Islands, U.S.</Option>
            <Option value="Wallis and Futuna">Wallis and Futuna</Option>
            <Option value="Western Sahara">Western Sahara</Option>
            <Option value="Yemen">Yemen</Option>
            <Option value="Zambia">Zambia</Option>
            <Option value="Zimbabwe">Zimbabwe</Option>
            <Option value="no response">Prefer not to answer</Option>
        </Select>
    );
};
