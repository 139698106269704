import React, { ReactFragment } from 'react';
import styled from 'styled-components';
import Button from 'antd/es/button';
import ArrowRightOutlined from '@ant-design/icons/lib/icons/ArrowRightOutlined';

interface Props {
    onClick: () => void;
    disabled?: boolean;
    message?: string | ReactFragment;
}

export const NextButton = (props: Props) => (
    <StyledButton type="primary" onClick={props.onClick} size="large" disabled={props.disabled}>
        {props.message ? (
            <React.Fragment>{props.message}</React.Fragment>
        ) : (
            <React.Fragment>
                Next <ArrowRightOutlined />
            </React.Fragment>
        )}
    </StyledButton>
);

export const StyledButton = styled(Button)`
    margin-top: ${({ theme }) => `${theme.spacing.xl}`};
`;
