import axios from 'axios';

import { SessionReport, UserDemographics } from '.';

export function getReport(
    sessionId: number,
    demographics: UserDemographics
): Promise<SessionReport> {
    return axios
        .post<SessionReport>('/api/report', { demographics: demographics, sessionId: sessionId })
        .then((resp) => resp.data);
}

export function getReportFromEncryptedSessionId(
    encryptedSessionId: string
): Promise<SessionReport> {
    return axios
        .post<SessionReport>('/api/report', { encryptedSessionId: encryptedSessionId })
        .then((resp) => resp.data);
}
